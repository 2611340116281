(<template>
  <div class="withdraw-buttons">
    <admin-only-note class="withdraw-buttons__wrapper">
      <div class="withdraw-buttons__btns-wrapper">
        <button class="sk-btn sk-btn--default withdraw-buttons__btn"
                @click="openWithdrawAsSupplierModal(true, {showSupplierNote: true, showCustomerNote: true})">{{ $gettext('Withdraw the interpreter') }}</button>
        <button class="sk-btn sk-btn--orange withdraw-buttons__btn"
                @click="openWithdrawAsSupplierModal(false, {showInternalNote: true})">{{ $gettext('Remove silently') }}</button>
        <button class="sk-btn sk-btn--orange withdraw-buttons__btn"
                @click="makeInterDecline">{{ $gettext('Remove interpreter') }}</button>
      </div>
    </admin-only-note>
  </div>
</template>)

<script>
  import {mapActions, mapState} from 'vuex';
  import AdminOnlyNote from '@/components/shared_components/AdminOnlyNote';

  export default {
    components: {
      'admin-only-note': AdminOnlyNote
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        jobId: ({storeJobObj}) => storeJobObj.id || '',
        discussionId: ({currentDiscussionId}) => currentDiscussionId || ''
      })
    },
    methods: {
      ...mapActions('OneAssignmentStore', ['makeWithdrawAsSupplier', 'makeDecline']),
      openWithdrawAsSupplierModal(withNotifications, showNotes = {}) {
        this.$store.commit('ModalStore/setModal', {
          component: 'withdraw-as-supplier-modal',
          classes: ['sk-modal--new'],
          width: 500,
          data: {
            context: this,
            title: this.$gettext('Withdraw from assignment'),
            withNotifications,
            showNotes
          }
        });
      },
      catchWithdrawAsSupplier(withNotifications, notesParams = {}) {
        const params = {
          params: {
            ...notesParams,
            notifications: withNotifications
          },
          discussionId: this.discussionId,
          jobId: this.jobId
        };
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.makeWithdrawAsSupplier(params).then(() => {
          const promiseArr = [
            this.$store.dispatch('OneAssignmentStore/getJobById', this.jobId),
            this.$store.dispatch('OneAssignmentStore/getDiscussions', this.jobId)
          ];
          Promise.all(promiseArr).then(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          });
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      makeInterDecline() {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.makeDecline(this.discussionId).then(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
    }
  };
</script>

<style scoped>
  .withdraw-buttons {
    display: flex;
  }

  .withdraw-buttons__wrapper {
    max-width: 100%;
    padding: 5px 10px 10px;
    border: 1px solid #f93;
    border-radius: 5px;
  }

  .withdraw-buttons__btns-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }

  .withdraw-buttons__btn {
    flex-grow: 1;
    width: auto;
    margin: 5px;
    padding: 0 15px;
  }
</style>
